<template>
    <PageLoader>
        <Header :title="$tc('contract_formula', 2)" heading="h2">
            <template v-slot:actions>
                <span class="flex space-x-10">
                    <span
                        ><Button @click="openCreateContractFormulaModal = true">{{ $tc('create_contract_formula', 1) }}</Button></span
                    >
                </span>
            </template>
        </Header>

        <section class="mt-5">
            <Table :total_count="total_contract_formula_count" :paginationOptions="paginations" :withSearch="false" @fetchData="getSearchContractFormulas">
                <table class="min-w-full divide-y divide-fibonaki-border">
                    <thead>
                        <tr>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('start_date', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('end_date', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">% {{ $tc('fix', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">% {{ $tc('variable', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900"># {{ $tc('ean', 2) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('action', 2) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="contract_formula in contract_formulas" :key="contract_formula">
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ contract_formula.start_date }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ contract_formula.end_date }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ contract_formula.fix_percentage }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ 100 - contract_formula.fix_percentage }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ contract_formula.eans_count }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">Actions</td>
                        </tr>
                    </tbody>
                </table>
            </Table>
        </section>

        <Modal :title="$tc('create_contract_formula', 1)" :show="openCreateContractFormulaModal" @submit="createContractFormula" @close="(openCreateContractFormulaModal = false), (this.contract_formula = {}), (this.validationErrors = {})" size="large">
            <div class="grid grid-cols-12 gap-x-3 gap-y-3">
                <div class="col-span-6">
                    <div class="grid grid-cols-12 gap-x-3 gap-y-3">
                        <div class="col-start-1 col-span-6">
                            <Select :label="$tc('energy_product', 1)" id="energy_product_id" :options="energyProductOptions" displayProperty="display_name" valueProperty="id" v-model="contract_formula.product_id" :error="validationErrors.product_id" />
                        </div>
                        <div class="col-start-1 col-span-6">
                            <Input :label="$tc('start_date', 1)" type="date" id="start_date" v-model="contract_formula.start_date" :error="validationErrors.start_date" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('end_date', 1)" type="date" id="end_date" v-model="contract_formula.end_date" :error="validationErrors.end_date" />
                        </div>
                        <div class="col-start-1 col-span-6">
                            <Input :label="$tc('fixed_fee', 1)" type="number" step="0.01" id="fixed_fee" v-model="contract_formula.fixed_fee" :error="validationErrors.fixed_fee" />
                        </div>
                        <div class="col-start-1 col-span-6" v-show="product === 'electricity'">
                            <Input :label="$tc('peak_tariff', 1)" type="number" step="0.01" id="peak_tariff" v-model="contract_formula.peak_tariff" :error="validationErrors.peak_tariff" />
                        </div>
                        <div class="col-span-6" v-show="product === 'electricity'">
                            <Input :label="$tc('green_energy_tariff', 1)" type="number" step="0.01" id="green_energy_tariff" v-model="contract_formula.green_energy_tariff" :error="validationErrors.green_energy_tariff" />
                        </div>
                        <div class="col-start-1 col-span-12" v-show="product === 'electricity'">
                            <RadioButtonGroup :label="$tc('netloss', 2)" id="contract_type" :options="netlossOptions" valueProperty="value" displayProperty="label" v-model="contract_formula.netlosses_included" />
                        </div>
                        <div class="col-start-1 col-span-6">
                            <RadioButtonGroup :label="$tc('contract_type', 1)" id="contract_type" :options="contractOptions" valueProperty="value" displayProperty="label" v-model="contract_formula.contract_type" />
                        </div>
                        <div class="col-start-1 col-span-6">
                            <Input :label="'% ' + $tc('fix', 1)" type="number" id="fix_percentage" v-model="contract_formula.fix_percentage" v-show="contract_formula.contract_type === 'combination'" />
                        </div>
                    </div>
                </div>
                <div class="col-span-6">
                    <div class="grid grid-cols-12 gap-x-3 gap-y-3">
                        <div class="col-span-12">
                            <MultiSelect :label="$tc('select_ean', 2)" :placeholder="$tc('select_ean', 2)" :options="clientEans" v-model="contract_formula.eans" displayProperty="ean_code" valueProperty="id" />
                        </div>
                    </div>
                </div>
                <div class="col-span-12" v-show="Object.keys(contract_formula.eans).length > 0">
                    <div class="grid grid-cols-12 gap-x-3 gap-y-3">
                        <div class="col-start-1 col-span-3" v-show="contract_formula.contract_type === 'fix' || contract_formula.contract_type === 'combination'"><Header :title="$tc('fix', 1)" heading="h4" /></div>
                        <div class="col-start-1 col-span-3" v-show="contract_formula.contract_type === 'fix' || contract_formula.contract_type === 'combination'">
                            <Select :label="$tc('energy_market_name', 1)" id="fix_energy_market_name" :options="filteredFixEnergyMarkets" displayProperty="name" valueProperty="name" v-model="contract_formula.fix_energy_market_name" :error="validationErrors.fix_energy_market_name" />
                        </div>
                        <div class="col-start-1 col-span-3" v-show="contract_formula.contract_type === 'fix' || contract_formula.contract_type === 'combination'">
                            <Input :label="$tc('mono', 1) + ' ' + $tc('price', 1)" type="number" step="0.001" id="fix_mono_price" v-model="contract_formula.fix_mono_price" :error="validationErrors.fix_mono_price" />
                        </div>

                        <div class="col-start-1 col-span-3" v-show="(contract_formula.contract_type === 'fix' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('day', 1) + ' ' + $tc('price', 1)" type="number" step="0.001" id="fix_day_price" v-model="contract_formula.fix_day_price" :error="validationErrors.fix_day_price" />
                        </div>

                        <div class="col-span-3" v-show="(contract_formula.contract_type === 'fix' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('night', 1) + ' ' + $tc('price', 1)" type="number" step="0.001" id="fix_night_price" v-model="contract_formula.fix_night_price" :error="validationErrors.fix_night_price" />
                        </div>

                        <div class="col-start-1 col-span-3" v-show="(contract_formula.contract_type === 'fix' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('exclusive', 1) + ' ' + $tc('price', 1)" type="number" step="0.001" id="fix_exclusive_price" v-model="contract_formula.fix_exclusive_price" :error="validationErrors.fix_exclusive_price" />
                        </div>

                        <div class="col-start-1 col-span-3" v-show="contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination'"><Header :title="$tc('variable', 1)" heading="h4" /></div>
                        <div class="col-start-1 col-span-3" v-show="contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination'">
                            <Select :label="$tc('energy_market_name', 1)" id="variable_energy_market_name" :options="filteredVariableEnergyMarkets" displayProperty="name" valueProperty="name" v-model="contract_formula.variable_energy_market_name" :error="validationErrors.variable_energy_market_name" />
                        </div>
                        <div class="col-start-1 col-span-3" v-show="contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination'">
                            <Input :label="$tc('mono', 1) + ' ' + $tc('factor', 1)" type="number" step="0.001" id="variable_mono_factor" v-model="contract_formula.variable_mono_factor" :error="validationErrors.variable_mono_factor" />
                        </div>
                        <div class="col-span-3" v-show="contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination'">
                            <Input :label="$tc('mono', 1) + ' ' + $tc('addon', 1)" type="number" step="0.001" id="variable_mono_addon" v-model="contract_formula.variable_mono_addon" :error="validationErrors.variable_mono_addon" />
                        </div>
                        <div class="col-start-1 col-span-3" v-show="(contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('day', 1) + ' ' + $tc('factor', 1)" type="number" step="0.001" id="variable_day_factor" v-model="contract_formula.variable_day_factor" :error="validationErrors.variable_day_factor" />
                        </div>
                        <div class="col-span-3" v-show="(contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('day', 1) + ' ' + $tc('addon', 1)" type="number" step="0.001" id="variable_day_addon" v-model="contract_formula.variable_day_addon" :error="validationErrors.variable_day_addon" />
                        </div>
                        <div class="col-span-3" v-show="(contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('night', 1) + ' ' + $tc('factor', 1)" type="number" step="0.001" id="variable_night_factor" v-model="contract_formula.variable_night_factor" :error="validationErrors.variable_night_factor" />
                        </div>
                        <div class="col-span-3" v-show="(contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('night', 1) + ' ' + $tc('addon', 1)" type="number" step="0.001" id="variable_night_addon" v-model="contract_formula.variable_night_addon" :error="validationErrors.variable_night_addon" />
                        </div>
                        <div class="col-start-1 col-span-3" v-show="(contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('exclusive', 1) + ' ' + $tc('factor', 1)" type="number" step="0.001" id="variable_exclusive_factor" v-model="contract_formula.variable_exclusive_factor" :error="validationErrors.variable_exclusive_factor" />
                        </div>
                        <div class="col-span-3" v-show="(contract_formula.contract_type === 'variable' || contract_formula.contract_type === 'combination') && product === 'electricity'">
                            <Input :label="$tc('exclusive', 1) + ' ' + $tc('addon', 1)" type="number" step="0.001" id="variable_exclusive_addon" v-model="contract_formula.variable_exclusive_addon" :error="validationErrors.variable_exclusive_addon" />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </PageLoader>
</template>

<script>
    import MultiSelect from '@/components/formElements/MultiSelect.vue';
    import ClientService from '@/services/ClientService';
    import InvoiceService from '@/services/InvoiceService';
    import EnergyDataService from '@/services/EnergyDataService';

    export default {
        components: {
            MultiSelect,
        },
        data() {
            return {
                contract_formula: {
                    product_id: null,
                    start_date: null,
                    end_date: null,
                    contract_type: null,
                    fix_percentage: null,
                    fixed_fee: null,
                    peak_tariff: null,
                    green_energy_tariff: null,
                    netlosses_included: false,
                    fix_energy_market_name: '',
                    fix_mono_price: null,
                    fix_day_price: null,
                    fix_night_price: null,
                    fix_exclusive_price: null,
                    variable_energy_market_name: '',
                    variable_mono_factor: null,
                    variable_mono_addon: null,
                    variable_day_factor: null,
                    variable_day_addon: null,
                    variable_night_factor: null,
                    variable_night_addon: null,
                    variable_exclusive_factor: null,
                    variable_exclusive_addon: null,
                    eans: [],
                },
                errors: {},
                validationErrors: {},
                openCreateContractFormulaModal: false,
                contract_formulas: {},
                total_contract_formula_count: 0,
                paginations: [5, 10, 20, 50],
                contractOptions: [
                    { label: this.$tc('fix', 1), value: 'fix' },
                    { label: this.$tc('variable', 1), value: 'variable' },
                    { label: this.$tc('combination', 1), value: 'combination' },
                ],
                netlossOptions: [
                    { label: this.$tc('included_in_price', 1), value: true },
                    { label: this.$tc('not_included_in_price', 1), value: false },
                ],
                energyProductOptions: [],
                fixEnergyMarketOptions: [],
                variableEnergyMarketOptions: [],
                clientEans: [],
            };
        },
        async created() {
            this.getSearchContractFormulas('', 0, this.paginations[0]);
            const energyProductsResponse = await InvoiceService.getEnergyProducts();
            this.energyProductOptions = energyProductsResponse.data.energy_products;

            if (this.energyProductOptions.length > 0) {
                this.contract_formula.product_id = this.energyProductOptions[0].id;
            }
            const fixEnergyMarketResponse = await EnergyDataService.getFixEnergyMarkets();
            this.fixEnergyMarketOptions = fixEnergyMarketResponse.data.energy_markets;

            const variableEnergyMarketResponse = await EnergyDataService.getVariableEnergyMarkets();
            this.variableEnergyMarketOptions = variableEnergyMarketResponse.data.energy_markets;

            const eansResponse = await ClientService.getEans(this.$route.params.client_id);
            this.clientEans = eansResponse.data.eans;
        },
        computed: {
            product() {
                if (this.contract_formula.product_id) {
                    return this.energyProductOptions.filter((item) => item.id === this.contract_formula.product_id)[0].name;
                }
                return '-';
            },
            filteredFixEnergyMarkets() {
                if (this.fixEnergyMarketOptions.length && this.contract_formula.product_id) {
                    return this.fixEnergyMarketOptions.filter((item) => item.energy_product_id === this.contract_formula.product_id);
                }
                return this.fixEnergyMarketOptions;
            },
            filteredVariableEnergyMarkets() {
                if (this.variableEnergyMarketOptions.length && this.contract_formula.product_id) {
                    return this.variableEnergyMarketOptions.filter((item) => item.energy_product_id === this.contract_formula.product_id);
                }
                return this.variableEnergyMarketOptions;
            },
        },
        methods: {
            async createContractFormula() {
                console.log('contract_formula', this.contract_formula);
                await ClientService.createContractFormula(this.$route.params.client_id, this.contract_formula);
                this.$router.push({ name: 'clientContractFormulas', params: { client_id: this.$route.params.client_id } });
            },
            async getSearchContractFormulas(search, offset, amount) {
                const contractFormulaResponse = await ClientService.getSearchContractFormulas(this.$route.params.client_id, search, offset, amount);
                this.contract_formulas = contractFormulaResponse.data.contract_formulas;
                this.total_contract_formula_count = contractFormulaResponse.data.total_count;
            },
        },
    };
</script>
